export const fonts = {
  topol: 'Topol',
  garamond: {
    condensed: 'ITCGaramondStd-LtCond',
  },
  monosten: {
    light: 'Monosten-Light',
  },
}

export const colors = {
  lightBrown: '#f5eee8',
  mediumBrown: '#eee6d7',
  darkBrown: '#66421c',
  goldenBrown: 'rgb(181,124,64)',
  warningRed: '#da1d37',
  mediumDarkBrown: '#ab917a',
}

export interface ThemeType {
  backgroundColor: string
  headerHeight: number
  textColorError: string
}

export const Theme: ThemeType = {
  backgroundColor: colors.lightBrown,
  headerHeight: 60,
  textColorError: colors.warningRed,
}

export default Theme
