import fetchJsonp from 'fetch-jsonp'

const SUBSCRIBED_EMAIL_STORAGE_KEY = 'subscribedEmail'

export enum SubscribeState {
  Initial = 'initial',
  Started = 'started',
  Complete = 'complete',
  Failed = 'failed',
}

export const getSubscribedEmail = () => {
  return localStorage.getItem(SUBSCRIBED_EMAIL_STORAGE_KEY)
}

const parseErrorMessage = (resultMsg: string) => {
  if (resultMsg.match('too many recent')) {
    return {
      state: SubscribeState.Failed,
      msg: 'This email address is already subscribed, and has too many subscribe requests',
    }
  } else if (resultMsg.match('is already subscribed')) {
    // Repeated email counts as success
    return {
      state: SubscribeState.Complete,
      msg: 'This email address is already subscribed',
    }
  } else if (resultMsg.match('0 -')) {
    return {
      state: SubscribeState.Failed,
      msg: 'Your email address is invalid',
    }
  } else if (resultMsg.match('email cannot be added')) {
    return {
      state: SubscribeState.Failed,
      msg: 'Your email address is invalid',
    }
  } else {
    return {
      state: SubscribeState.Failed,
      msg: 'Unable to sign up. Try again later',
    }
  }
}

const groups = {
  mailingList: {
    value: '1',
    key: 'group[24045][1]',
  },
  waitlist: {
    value: '4',
    key: 'group[24045][4]',
  },
}

type GroupName = keyof typeof groups

export const subscribe = async (
  email: string,
  name: string,
  group: GroupName,
) => {
  let url =
    'https://oda.us5.list-manage.com/subscribe/post-json?u=9c7975b241bdb5bb1c7b81c47&amp;id=3f00cba943&c=jsonp' +
    '&EMAIL=' +
    encodeURIComponent(email)

  if (name !== '') {
    const [firstName, lastName] = name.split(/ (.*)/)
    if (firstName) url += '&FNAME=' + firstName
    if (lastName) url += '&LNAME=' + lastName
  }

  url += `&${groups[group].key}=&${groups[group].value}`

  try {
    const response = await fetchJsonp(url, {
      jsonpCallbackFunction: 'jsonp',
    })
    const data = await response.json()
    if (data.result === 'error') {
      const parsedMsg = parseErrorMessage(data.msg)
      return parsedMsg
    } else {
      return { state: SubscribeState.Complete, msg: 'Success!' }
    }
  } catch (error) {
    return {
      state: SubscribeState.Failed,
      msg: 'Unable to sign up. Try again later',
    }
  }
}

export const subscribeMember = async (
  email: string,
  name: string,
  referrer?: string,
) => {
  let url =
    'https://oda.us5.list-manage.com/subscribe/post?u=9c7975b241bdb5bb1c7b81c47&amp;id=20d682782a&c=jsonp' +
    '&EMAIL=' +
    encodeURIComponent(email)

  if (name !== '') {
    const [firstName, lastName] = name.split(/ (.*)/)
    if (firstName) url += '&FNAME=' + firstName
    if (lastName) url += '&LNAME=' + lastName
  }

  if (referrer) {
    url += '&REFERRER=' + referrer
  } else {
    url += '&REFERRER=unknown'
  }

  try {
    const response = await fetchJsonp(url, {
      jsonpCallbackFunction: 'jsonp',
    })
    const data = await response.json()
    if (data.result === 'error') {
      const parsedMsg = parseErrorMessage(data.msg)
      return parsedMsg
    } else {
      return { state: SubscribeState.Complete, msg: 'Success!' }
    }
  } catch (error) {
    return {
      state: SubscribeState.Failed,
      msg: 'Unable to sign up. Try again later',
    }
  }
}
