import {
  default as emotionStyled,
  CreateStyled,
  CSSObject,
} from '@emotion/styled'
import { useTheme as emotionUseTheme } from '@emotion/react'
import Themes, { ThemeType, fonts } from './Themes'
import facepaint from 'facepaint'

export { default as Themes, fonts, colors } from './Themes'

export const styled = emotionStyled as CreateStyled

export const useTheme = () => {
  return emotionUseTheme()
}

export const mq = facepaint([
  '@media(max-width: 800px)',
  '@media(max-width: 500px)',
  '@media(max-width: 330px)',
])

export const typography = {
  extraLargeSerif: mq({
    fontFamily: fonts.garamond.condensed,
    fontSize: ['112px', '64px'],
    lineHeight: ['101px', 0.9],
  }),
  largeSerif: mq({
    fontFamily: fonts.garamond.condensed,
    fontSize: ['64px'],
    lineHeight: [0.9],
  }),
  mediumSerif: mq({
    fontFamily: fonts.garamond.condensed,
    fontSize: ['48px', '24px'],
    lineHeight: [1.2],
  }),
  smallSerif: mq({
    fontFamily: fonts.garamond.condensed,
    fontSize: ['32px', '18px'],
    lineHeight: [1.2],
  }),
  tinySerif: mq({
    fontFamily: fonts.garamond.condensed,
    fontSize: ['24px'],
    lineHeight: [1.2],
  }),
  tinyTinySerif: mq({
    fontFamily: fonts.garamond.condensed,
    fontSize: ['20px'],
    lineHeight: [1.2],
  }),
  largeSans: mq({
    fontFamily: fonts.topol,
    fontSize: ['64px', '32px'],
    lineHeight: ['1.1'],
  }),
  mediumSans: mq({
    fontFamily: fonts.topol,
    fontSize: ['42px', '22px'],
    lineHeight: [1.2],
  }),
  smallSans: mq({
    fontFamily: fonts.topol,
    fontSize: ['32px', '16px'],
    lineHeight: [1.2],
  }),
  smallMono: mq({
    fontFamily: fonts.monosten.light,
    fontSize: ['24px'],
    lineHeight: [1.3],
  }),
  tinyMono: mq({
    fontFamily: fonts.monosten.light,
    fontSize: ['14px', '12px'],
    lineHeight: ['20px'],
    '-webkit-font-smoothing': 'antialiased',
  }),
}

export const spaces = {
  large: [100, 50, 50],
  medium: [60, 30, 30],
  small: [30, 15, 15],
}

export const absoluteCenter = (width: number, height: number): CSSObject => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginLeft: -width / 2,
  marginTop: -height / 2,
  width: width,
  height: height,
})

export const widthToFill = (width: number) =>
  mq({
    maxWidth: [width, '100%', '100%', '100%'],
  })

export const fullWidth = widthToFill(1000)

export default {
  Themes,
  styled,
  useTheme,
  mq,
}
