import { ThemeProvider } from '@emotion/react'
import React, { FC, useCallback, useRef, useState } from 'react'
import { recordMailingListSignup } from '../lib/Analytics'
import { GroupName, subscribe, SubscribeState } from '../lib/SubscribeUtils'
import { colors, fonts, mq, spaces, styled, typography } from '../styles'
import Button from './Button'

const useSignup = (group: GroupName) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [subscribeState, setSubscribeState] = useState<SubscribeState>(
    SubscribeState.Initial,
  )

  const emailInputRef = useRef(null)

  const handleInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target
      const value = target.value
      if (target.name === 'name') setName(value)
      else setEmail(value)
    },
    [],
  )

  const handleSubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      if (email === '') {
        setErrorMessage('Please fill in your name and email.')
        setSubscribeState(SubscribeState.Failed)
      } else {
        setSubscribeState(SubscribeState.Started)
        const result = await subscribe(email, name, group)
        if (result.state !== SubscribeState.Complete) {
          setErrorMessage(result.msg)
          setSubscribeState(SubscribeState.Failed)
        } else {
          //do success thing
          setErrorMessage(undefined)
          setSubscribeState(SubscribeState.Complete)
          recordMailingListSignup()
        }
      }
    },
    [name, email],
  )

  return {
    name,
    email,
    emailInputRef,
    handleInputChanged,
    handleSubmit,
    errorMessage,
    state: subscribeState,
  }
}

export interface SignupFormTheme {
  foregroundColor: string
  backgroundColor: string
  placeholderColor: string
  buttonColor: string
  buttonTextColor: string
  inputBorder: string
  inputBackgroundColor: string
}

export const themes: { [K: string]: SignupFormTheme } = {
  light: {
    foregroundColor: 'black',
    backgroundColor: colors.lightBrown,
    placeholderColor: '#B6B4AF',
    buttonColor: colors.darkBrown,
    buttonTextColor: colors.lightBrown,
    inputBorder: '1px solid black',
    inputBackgroundColor: '#e6e1da',
  },
  dark: {
    foregroundColor: colors.lightBrown,
    backgroundColor: colors.darkBrown,
    placeholderColor: '#B6B4AF',
    buttonColor: colors.lightBrown,
    buttonTextColor: colors.darkBrown,
    inputBorder: 'none',
    inputBackgroundColor: colors.lightBrown,
  },
}

const EmailSignupForm: FC<{ theme: 'light' | 'dark'; group?: GroupName }> = ({
  theme = 'light',
  group = 'mailingList',
}) => {
  const signup = useSignup(group)

  return (
    <ThemeProvider theme={themes[theme]}>
      <Form>
        {signup.state !== SubscribeState.Complete && (
          <>
            <TextInputs>
              <Input
                name="name"
                placeholder="Name"
                value={signup.name}
                onChange={signup.handleInputChanged}
              />
              <Input
                name="email"
                type="email"
                placeholder="Email"
                value={signup.email}
                onChange={signup.handleInputChanged}
              />
              {signup.state == SubscribeState.Failed && (
                <ErrorMessage>{signup.errorMessage}</ErrorMessage>
              )}
            </TextInputs>

            <BigButton
              size={'large'}
              colorScheme={theme === 'dark' ? 'light' : 'dark'}
              disabled={signup.state === SubscribeState.Started}
              onClick={signup.handleSubmit}>
              Sign Up
            </BigButton>
          </>
        )}

        {signup.state === SubscribeState.Complete && (
          <SuccessMessage>
            You're on the list!
            <br />
            We'll be in touch soon.
          </SuccessMessage>
        )}
      </Form>
    </ThemeProvider>
  )
}

export default EmailSignupForm

const Form = styled.form(mq({}))

const TextInputs = styled.div(
  mq({
    width: [1000, 'auto'],
    maxWidth: ['100%'],
    margin: '0 auto',
    marginBottom: spaces.small,
  }),
)

const Input = styled.input(({ theme: t }) => {
  const theme = t as SignupFormTheme
  return mq({
    display: 'block',
    width: [670, '100%'],
    maxWidth: '100%',
    border: theme.inputBorder,
    borderRadius: [10, 7, 7, 7],
    padding: '10px 20px 10px 20px',
    margin: '0 auto',
    marginBottom: spaces.small,
    backgroundColor: theme.inputBackgroundColor,
    fontFamily: fonts.monosten.light,
    fontSize: ['80px', '32px', '32px'],
    '::-webkit-input-placeholder': {
      color: theme.placeholderColor,
    },
    ':-webkit-autofill': {
      boxShadow: `0px 0px 0px 60px #E6E1DA inset`,
    },
    ':-webkit-autofill::first-line': {
      fontSize: ['80px', '72px', '72px'],
    },
    ':last-child': {
      marginBottom: [0, 0, 0, 0],
    },
    ':focus': {
      borderRadius: 10,
      outline: 'none',
      boxShadow: `2px 2px 0px grey`,
    },
  })
})

const ErrorMessage = styled.div(
  typography.tinyMono,
  mq({
    textAlign: 'center',
  }),
)

const SuccessMessage = styled.div(
  typography.smallSans,
  mq({
    textAlign: 'center',
  }),
)

const BigButton = styled(Button)(
  mq({
    width: ['875px', '100%'],
    maxWidth: '100%',
    margin: '0 auto',
  }),
)
