import { Link } from 'gatsby'
import React, { FC } from 'react'
import Logo from '../images/logo.png'
import { absoluteCenter, fonts, mq, styled } from '../styles'
import { DataName } from '../types'
import { InternalLinkButton } from './Button'
import WaitlistButton from './WaitlistButton'

const Header: FC<{ tabNames?: DataName[] }> = (props) => {
  return (
    <Nav role="menu">
      <Container>
        <HeaderContent>
          <HomeLink to={'/'}>Oda</HomeLink>
          <LogoLink to={'/'}>
            <LogoImage src={Logo} />
          </LogoLink>
          <NavButtons>
            <FaqLink to="/faq">FAQ</FaqLink>
            <WaitlistButton />
          </NavButtons>
        </HeaderContent>
      </Container>
    </Nav>
  )
}

const Nav = styled('nav')(
  mq({
    height: [60],
  }),
)

const Container = styled('div')(
  {
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    borderColor: 'black',
    borderBottom: 'solid',
    borderBottomWidth: 1,
    zIndex: 10,
  },
  (props) => ({
    height: props.theme.headerHeight,
    backgroundColor: props.theme.backgroundColor,
  }),
)

const HeaderContent = styled('div')<{ leftBorderWidth?: number | string }>(
  {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1.1em',
    height: '100%',
    width: '100%',
  },
  (props) => ({
    borderLeftWidth: props.leftBorderWidth ? props.leftBorderWidth : 1,
  }),
  mq({
    padding: ['0 1.1em', '0 .7em'],
  }),
)

const HomeLink = styled(Link)({
  textDecoration: 'none',
  fontFamily: fonts.garamond.condensed,
  fontSize: '3em',
  paddingTop: '.3em',
})

const LogoLink = styled(Link)(
  {
    display: 'none',
    ...absoluteCenter(46, 46),
  },
  mq({
    left: ['50%', 103],
  }),
)

const LogoImage = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
})

const NavButtons = styled('div')({
  display: 'flex',
})

const FaqLink = styled(InternalLinkButton)(
  {
    backgroundColor: 'transparent',
    border: '1px solid black',
    color: 'black',
    margin: 'auto',
  },
  mq({
    marginRight: [10, 10, 10, 7],
    // padding: ['3px 25px 6px', '8px 20px', '8px 20px', '7px 10px'],
  }),
)

export default Header
