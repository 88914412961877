import styled from '@emotion/styled'
import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'

import { colors } from '../styles'
import useAppearEffect from '../lib/useAppearEffect'

const getMediaContainerPadding = (aspectRatio: number) => {
  return ((1 / aspectRatio) * 100).toFixed(6) + '%'
}

interface MediaPlaceholderProps {
  className?: string
  aspect?: number
  background?: string
  onAppear?: () => void
}

const MediaPlaceholder: FC<PropsWithChildren<MediaPlaceholderProps>> = ({
  className,
  aspect,
  background,
  children,
  onAppear,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  useAppearEffect(containerRef, onAppear)

  // Trigger initial appearance event if we can't use
  // IntersectionObserver
  useEffect(() => {
    if (!window.IntersectionObserver) {
      onAppear && onAppear()
    }
  }, [])

  const padding = getMediaContainerPadding(aspect || 1)
  const backgroundColor = background || colors.mediumBrown

  return (
    <OuterContainer className={className}>
      <InnerContainer
        ref={containerRef}
        style={{
          paddingBottom: padding,
          backgroundColor,
        }}>
        {children}
      </InnerContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled('div')({
  position: 'relative',
})

const InnerContainer = styled('div')({
  position: 'relative',
  height: 0,
  boxSizing: 'content-box',
})

export default MediaPlaceholder
