import React, { FC, useCallback, useState } from 'react'
import { colors, mq, spaces, styled, typography } from '../styles'
import Button, { ButtonProps } from './Button'
import EmailSignupForm from './EmailSignupForm'

type WaitlistButtonProps = Pick<ButtonProps<'button'>, 'size' | 'colorScheme'>

const WaitlistButton: FC<WaitlistButtonProps> = ({
  size = 'small',
  colorScheme = 'dark',
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    setIsOpen((v) => !v)
  }, [])

  const handleModalClosed = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <WaitlistButtonContainer>
      <ButtonComponent
        size={size}
        colorScheme={colorScheme}
        onClick={toggleOpen}>
        JOIN THE WAITLIST
      </ButtonComponent>
      {isOpen && <WaitlistModal onClose={handleModalClosed} />}
    </WaitlistButtonContainer>
  )
}

export default WaitlistButton

const WaitlistButtonContainer = styled.div(mq({}))

const WaitlistModal: FC<{ onClose?: () => void }> = ({ onClose }) => {
  return (
    <WaitlistModalContainer>
      <WaitlistModalOverlay onClick={onClose} />
      <WaitlistModalContent>
        <WaitlistModalTitle>Get On The List</WaitlistModalTitle>
        <WaitlistModalSubtitle>
          You'll be the first to know when units are ready.
        </WaitlistModalSubtitle>
        <EmailSignupForm group={'waitlist'} theme={'dark'} />
      </WaitlistModalContent>
    </WaitlistModalContainer>
  )
}

const WaitlistModalContainer = styled.div(
  mq({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 11,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'auto',
    padding: spaces.small,
  }),
)

const WaitlistModalOverlay = styled.div(
  mq({
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightBrown,
    opacity: 0.95,
    cursor: 'pointer',
  }),
)

const WaitlistModalContent = styled.div(
  mq({
    display: 'flex',
    flexDirection: 'column',
    minHeight: [665, 0],
    position: 'relative',
    zIndex: 1,
    backgroundColor: colors.darkBrown,
    color: colors.lightBrown,
    borderRadius: 30,
    padding: spaces.small,
    flexShrink: 0,
  }),
)

const WaitlistModalTitle = styled.div(
  typography.extraLargeSerif,
  mq({
    textAlign: 'center',
    paddingTop: spaces.small,
  }),
)

const WaitlistModalSubtitle = styled.div(
  typography.tinyMono,
  mq({
    textAlign: 'center',
    marginBottom: spaces.small,
  }),
)

const ButtonComponent = styled(Button)(({ size }) =>
  mq({
    ...(size === 'large'
      ? {
          width: ['1200px', '100%'],
          maxWidth: '100%',
        }
      : {}),
  }),
)
