import React, { FC, useCallback, useState } from 'react'

import { colors, styled } from '../../styles'
import { Maybe, PrismicImageFluidType } from '../../../graphql-types'
import MediaPlaceholder from '../MediaPlaceholder'

export type ImageFragment = Maybe<{
  fluid?: Pick<PrismicImageFluidType, 'src' | 'srcSet' | 'aspectRatio'> | null
}>

interface FluidImageProps {
  className?: string
  aspect?: number
  transparent?: boolean
  lazy?: boolean
  image?: ImageFragment
  imageStyle?: React.CSSProperties
  alt?: string | null
  sizes?: string
}

const FluidImage: FC<FluidImageProps> = ({
  image,
  className,
  aspect,
  transparent,
  lazy,
  imageStyle,
  alt,
  sizes,
}) => {
  const isLazy = typeof lazy === 'undefined' || lazy
  const [shouldDisplay, setShouldDisplay] = useState(!isLazy)
  const appearCallback = useCallback(() => {
    setShouldDisplay(true)
  }, [])

  const backgroundColor = transparent ? 'transparent' : colors.mediumBrown
  return (
    <MediaPlaceholder
      className={className}
      onAppear={appearCallback}
      aspect={aspect || image?.fluid?.aspectRatio || 1}
      background={backgroundColor}>
      {shouldDisplay && (
        <BaseImage
          src={image?.fluid?.src}
          srcSet={image?.fluid?.srcSet}
          style={imageStyle}
          alt={alt ? alt : ''}
          sizes={sizes}
        />
      )}
    </MediaPlaceholder>
  )
}

const BaseImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  bottom: 0,
})

export default FluidImage
