import React, { FC } from 'react'

import { fonts, mq, styled } from '../styles'
import { FooterFragment, Maybe } from '../../graphql-types'
import FluidImage from './base/FluidImage'

const Footer: FC<{
  data?: Maybe<FooterFragment>
}> = ({ data }) => {
  return (
    <Container>
      <Content>
        <FooterSection>
          <FooterSectionHeader>Follow Oda</FooterSectionHeader>
          <SocialLinksContainer>
            {data?.footer_social_links?.map((l, i) => {
              return (
                <SocialLink key={i} href={l?.link?.url || undefined}>
                  <SocialLinkImage image={l?.image} transparent aspect={1} />
                </SocialLink>
              )
            })}
          </SocialLinksContainer>
        </FooterSection>

        <FooterSection>
          <FooterSectionHeader>Contact Oda</FooterSectionHeader>
          <ContactLinkContainer>
            <ContactLink href={data?.footer_contact_email?.url || undefined}>
              {data?.footer_contact_link_text?.text}
            </ContactLink>
          </ContactLinkContainer>
        </FooterSection>

        <FooterSection>
          <FooterSectionHeader>Legal Oda</FooterSectionHeader>
          <TermsLinksContainer>
            {data?.footer_links?.map((l, i) => {
              return (
                <TermsLink key={i} href={`/${l?.link?.document?.data?.slug}`}>
                  {l?.text}
                </TermsLink>
              )
            })}
          </TermsLinksContainer>
        </FooterSection>
      </Content>
    </Container>
  )
}

const Container = styled('div')({
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  paddingTop: ['2em', '2em'],
  borderTop: '1px solid black',
})

const Content = styled('div')(
  {
    display: 'flex',
    margin: '0 auto',
  },
  mq({
    maxWidth: [1000, 'auto'],
    flexDirection: ['row', 'column'],
    paddingBottom: '3em',
  }),
)

const FooterSection = styled('div')(
  {},
  mq({
    textAlign: 'center',
    width: ['33%', 'auto'],
    marginBottom: [0, '2em'],
    ':last-child': {
      marginBottom: 0,
    },
  }),
)

const FooterSectionHeader = styled('div')({
  fontFamily: fonts.monosten.light,
  fontSize: '.75em',
  marginBottom: '1em',
})

const ContactLinkContainer = styled(FooterSection)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
})

const ContactLink = styled('a')({
  display: 'block',
  fontSize: '3em',
  fontFamily: fonts.garamond.condensed,
  textDecoration: 'none',
  position: 'relative',
  height: '1em',
})

const SocialLinksContainer = styled('div')(
  mq({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  }),
)

const SocialLink = styled('a')({
  margin: '0 .3em',
  display: 'block',
})

const SocialLinkImage = styled(FluidImage)({
  width: 50,
})

const TermsLinksContainer = styled(FooterSection)(
  mq({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 1.4,
    width: '100%',
  }),
)

const TermsLink = styled('a')(
  mq({
    display: 'block',
    margin: '0 .5em',
    textDecoration: 'none',
    lineHeight: 1.2,
    fontSize: '1.2em',
  }),
)

export default Footer
