import { ThemeProvider, Theme } from '@emotion/react'
import { Link } from 'gatsby'
import React, {
  ComponentProps,
  ComponentType,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { animated, useSpring } from 'react-spring'
import { colors, fonts, mq, styled } from '../styles'

interface ButtonSizeTheme {
  fontSize: number | (number | string)[]
  padding: number | (number | string)[]
}
interface ButtonColorTheme {
  textColor: string
  backgroundColor: string
}

const sizeThemes = {
  large: {
    fontSize: ['88px', '26px'],
    padding: ['20px 40px 24px', '7px 0 9px'],
  },
  medium: {
    fontSize: ['48px', '26px'],
    padding: ['1px 100px 6px', '8px 40px'],
  },
  small: {
    fontSize: ['1.5em', '1em'],
    padding: ['3px 24px 6px', '8px 20px'],
  },
}

const colorThemes = {
  light: {
    textColor: colors.darkBrown,
    backgroundColor: colors.lightBrown,
  },
  dark: {
    textColor: colors.lightBrown,
    backgroundColor: colors.darkBrown,
  },
}

type ButtonTheme = ButtonSizeTheme & ButtonColorTheme

export type ButtonProps<T> = Pick<
  HTMLAttributes<T>,
  'onMouseDown' | 'onMouseUp' | 'onMouseOut' | 'style'
> & { colorScheme?: 'dark' | 'light'; size?: 'large' | 'medium' | 'small' }

const makeButtonComponent = <E extends HTMLElement, P>(
  ButtonComponent: ComponentType<ButtonProps<E> & P>,
) => ({
  style,
  colorScheme = 'dark',
  size = 'small',
  ...rest
}: ComponentProps<typeof ButtonComponent>) => {
  const [isDown, setIsDown] = useState(false)
  const spring = useSpring({
    scale: isDown ? 0.9 : 1,
    config: { mass: 1, tension: 550, friction: 27 },
  })

  const theme = useMemo(() => {
    return {
      ...sizeThemes[size],
      ...colorThemes[colorScheme],
    }
  }, [colorScheme, size])

  const handleMouseDown = useCallback((e) => {
    setIsDown(true)
  }, [])

  const handleMouseUp = useCallback(() => {
    setIsDown(false)
  }, [])

  const handleMouseOut = useCallback(() => {
    setIsDown(false)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ButtonComponent
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseOut={handleMouseOut}
        {...(rest as P)}
        style={{
          ...style,
          transform: spring.scale.interpolate((s) => `scale(${s}, ${s})`),
        }}></ButtonComponent>
    </ThemeProvider>
  )
}

const buttonStyles = <T,>({
  theme: t,
  size,
}: { theme: Theme } & ButtonProps<T>) => {
  const theme = t as ButtonTheme
  return mq({
    display: 'block',
    margin: '0 auto',
    textDecoration: 'none',
    borderRadius: 1000,
    cursor: 'pointer',
    userSelect: 'none',
    transformOrigin: 'center center',
    fontFamily: fonts.topol,
    textTransform: 'uppercase',
    // border: 0,
    WebkitFontSmoothing: 'antialiased',
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
    fontSize: theme.fontSize,
    lineHeight: 1,
    border: '1px solid transparent',
    padding: theme.padding,
    letterSpacing: size === 'large' ? ['1px', 0] : 0,
  })
}

const BaseButton = styled(animated('button'))(buttonStyles)

const BaseLinkButton = styled(animated('a'))(buttonStyles)

const BaseInternalLinkButton = styled(animated(Link))(buttonStyles)

export const Button = makeButtonComponent(BaseButton)

export const LinkButton = makeButtonComponent(BaseLinkButton)

export const InternalLinkButton = makeButtonComponent(BaseInternalLinkButton)

export default Button
