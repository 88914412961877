import React, { FC } from 'react'
import { ThemeProvider } from '@emotion/react'

import config from '../config'
import Header from './Header'
import { Themes } from '../styles'
import { DataName } from '../types'
import Footer from './Footer'
import SEO from './SEO'
import { FooterFragment, Maybe, MetaFragment } from '../../graphql-types'

const Layout: FC<{
  tabNames?: DataName[]
  footer?: Maybe<FooterFragment>
  meta?: Maybe<MetaFragment>
  showFinalPreorder?: boolean
}> = ({ footer, tabNames, children, meta }) => {
  return (
    <ThemeProvider theme={Themes}>
      <SEO
        title={meta?.page_title}
        description={meta?.page_description}
        keywords={meta?.keywords?.map((k) => k?.keyword || '')}
        lang="en"
        socialImage={meta?.social_image?.fixed?.src}
      />
      {config.hasHeader && <Header tabNames={tabNames} />}
      {children}
      {footer && <Footer data={footer} />}
    </ThemeProvider>
  )
}

export default Layout
