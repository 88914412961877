import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Maybe } from '../../graphql-types'

interface SEOProps {
  title?: string | null
  description?: string | null
  keywords?: Maybe<string[]> | Maybe<string>[]
  siteUrl?: string | null
  lang?: string
  socialImage?: string
}

const SEO: FC<SEOProps> = (props) => {
  const metaDescription = props.description || ''
  const metaKeywords = props.keywords || ['']
  const metaTitle = props.title || ''
  const lang = props.lang

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords.join(','),
        },
        {
          property: 'og:url',
          content: 'https://oda.co',
        },
        {
          property: 'fb:app_id',
          content: '275509830369139',
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: props.socialImage, // https://oda.co/oda_banner.png'
        },
        {
          property: 'og:image:width',
          content: '1656',
        },
        {
          property: 'og:image:height',
          content: '930',
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
      ]}>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
} as Partial<SEOProps>

export default SEO
