const branch = process.env.GATSBY_BRANCH || process.env.BRANCH

export const recordShopLinkClick = (location: string) => {
  if (typeof analytics !== 'undefined') {
    analytics.track('Shop Link Clicked', {
      location: location,
      branch,
    })
  }
}

export const recordMailingListSignup = () => {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'Lead', {
      content_category: 'email_list',
      branch,
    })
  }

  if (typeof analytics !== 'undefined') {
    analytics.track('Mailing List Signup', {
      branch,
    })
  }
}

export const recordPageView = (pageName: string) => {
  if (typeof analytics !== 'undefined') {
    analytics.page(pageName, {
      branch,
    })
  }
}

export const recordFaqView = (topic: string) => {
  if (typeof analytics !== 'undefined') {
    analytics.track('Faq Topic Viewed', {
      topic,
      branch,
    })
  }
}

export const recordReferralSignupStart = (email: string, code?: string) => {
  if (typeof analytics !== 'undefined') {
    analytics.track('Referral Signup Start', {
      email,
      code,
      branch,
    })
  }
}

export const recordReferralSignupFinish = (email: string, code?: string) => {
  if (typeof analytics !== 'undefined') {
    analytics.track('Referral Signup Finish', {
      email,
      code,
      branch,
    })
  }
}

export const recordReferralSignupFail = (
  email: string,
  error?: string,
  code?: string,
) => {
  if (typeof analytics !== 'undefined') {
    analytics.track('Referral Signup Fail', {
      email,
      error,
      code,
      branch,
    })
  }
}
