const config = {
  hasHeader: true,
  showNavigator: false,
  sanityImages: {
    hero: 'https://cdn.sanity.io/images/dobh0e9j/production/7523e75862402c004d88110845eed7afcffd4dbd-5472x3648.jpg',
    howItFeels:
      'https://cdn.sanity.io/images/dobh0e9j/production/148f5c46000c206066cfbf71666976ec818c2e64-2016x1135.jpg',
    membership:
      'https://cdn.sanity.io/images/dobh0e9j/production/d4fc38f39f127e25b0ea8ed976dc14330eef328f-2417x1673.jpg',
    summerProgram:
      'https://cdn.sanity.io/images/dobh0e9j/production/99aa6d29f46cce7b3e98215c5820ba3f0459f361-4200x2768.jpg',
    systemMembership:
      'https://cdn.sanity.io/images/dobh0e9j/production/ffa3bd8e10c8c16199624af5ea06cd3445fb2e6a-715x729.png',
    systemSpeaker:
      'https://cdn.sanity.io/images/dobh0e9j/production/2219cd1f1b4276a5c8b9d5558eec919e130ccf84-797x797.png',
    slides: [
      'https://cdn.sanity.io/images/dobh0e9j/production/60775b47d73f48086e52bb10735eca3ee36c4536-4314x2906.jpg',
      'https://cdn.sanity.io/images/dobh0e9j/production/2ff3699e758b230e02c6cea31e1a221b99098903-4321x2910.jpg',
      'https://cdn.sanity.io/images/dobh0e9j/production/7654a684ea84e796e2f457bae9bc7366cc55ef76-4321x2910.jpg',
      'https://cdn.sanity.io/images/dobh0e9j/production/9b5ba9550cc74fec16649c085c86cddf59b55b3f-4321x2910.jpg',
    ],
  },
  sanityProjectId: 'dobh0e9j',
  sanityDataset: 'production',
  apiRoot: 'https://api.oda.co/v1',
}

export default config
