import React, { RefObject, useEffect, useState } from 'react'

const useAppearEffect = <T extends HTMLElement>(
  ref: RefObject<T | undefined>,
  fn?: () => void,
) => {
  const [hasAppeared, setHasAppeared] = useState(false)

  useEffect(() => {
    const isSupported = !!window.IntersectionObserver
    const target = ref.current

    if (!isSupported || !target) return

    const observer = new IntersectionObserver(
      (entries, b) => {
        if (entries[0].isIntersecting && !hasAppeared) {
          setHasAppeared(true)
          fn && fn()
        }
      },
      {
        rootMargin: '100%',
        threshold: 0,
      },
    )
    observer.observe(target)
    return () => {
      observer.unobserve(target)
    }
  }, [fn])

  return { hasAppeared }
}

export default useAppearEffect
